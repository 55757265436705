<template>
  <v-container>
    <v-card flat color="red" max-width="700" class="mx-auto my-12 py-5 text-center" dark>
      <p class="titulo-success">An error occurred while processing the payment</p>
      <v-icon x-large color="white">
            mdi-alert-circle
      </v-icon>
    </v-card>
    <p class="text-center text-h6">Your account was created but the payment was not processed</p>
    <p class="text-center text-h6">Please go to your account and press upgrade to pro</p>
    <v-row>
      <v-btn color="blue" to="/login" class="mx-auto my-2" x-large dark>
        Go to Account  
      </v-btn>
    </v-row>
    
    
  </v-container>
</template>
<script>
import { functions } from '../../plugins/firebase'

export default {
  data() {
    return {
    }
  },
  created () {

  },
  methods: {

  },
  mounted() {
    // let date = new Date();
    // let fechaFormato = date.toLocaleString();
    // console.log(date)
    // console.log(fechaFormato)
    // console.log(date.getFullYear()+1)
    // console.log(date.getMonth())
    // console.log(date.getDate())
  }
}
</script>
<style>
  .titulo-success{
    font-size: 35px;
    font-weight: 500;

    margin: 5px;
  }
</style>